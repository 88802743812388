
.bg-light {
    background-color: var(--color5) !important;
}







.mainContainer {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

}

.innerPage {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 75px 15px 20px 15px;
    margin: 0 auto;
    display: block;
    position: relative;
}

.ameyoLeftFix {
    .innerPage {
        width: calc(100% - 270px);
        margin-left: 270px;
    }

    .ameyoCalling {
        width: 275px;
        height: calc(100% - 53px);
    }

    .iframeCSS {
        width: 271px !important;
        height: calc(100% - 34px) !important;
    }
}


.appNav {
    width: 100%;
    height: 55px;
    padding: 0;
    background: var(--color5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    border-bottom: 2px solid var(--bs-gray-400);

    .topbarLogo {

        padding: 10px;

        img {
            height: 30px;
        }
    }

    .menuBtn {
        width: 32px;
        height: 32px;
        margin-left: 10px;
        cursor: pointer;

        svg {
            height: inherit;
            width: inherit;
            fill: var(--secondary)
        }

    }

    .menuBtn:hover {

        svg {
            height: inherit;
            width: inherit;
            fill: #f3be10
        }
    }

    >div:last-child {
        margin-left: auto;
        cursor: pointer;

        .list-group-item {
            font-size: 13px;

        }

        .list-group-item i {
            font-size: 18px;
        }

        .list-group-item {
            padding: 4px 8px !important;
        }
    }

    .headeUser {
        font-weight: 500;
        margin-left: auto;
        gap: 5px;
        line-height: 15px;
        height: 28px;
        max-width: 165px;
        overflow: hidden;

        span {
            font-size: 13px;
            color: var(--bs-gray-500);
            font-weight: 400;
        }

        >div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

}


.sideMenu {
    .offcanvas-header {
        justify-content: end;
    }

    .iconLogo {
        width: 100px;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: 100%;
        }
    }

}

.userMenu {
    height: 55px;
    padding: 0;
    display: flex;
    align-items: center;

    .btn-group {
        border-radius: 0;
        background: rgba(0, 0, 0, 0.05);
        transition: all .5s;

        div {
            padding: 4px 10px;
            line-height: 16px;
            font-weight: 500;

            span {
                color: var(--bs-gray-600);
                font-weight: normal;
                font-style: italic;
                font-size: 12px;
            }
        }
    }

    .btn-group:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    .dropdown-menu {}
}

.sideMenuList {
    display: flex;
    justify-content: space-between;
    ;
    flex-direction: column;

    .accordion-item {
        border: none;
    }

    .accordion-button {
        color: var(--bs-gray-800);
        ;
        padding: 8px;
        font-size: 14px;
        gap: 4px;
        border-bottom: 1px dotted var(--bs-gray-300);
    }

    .accordion-button:hover {
        color: var(--bs-info);
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none;
        background: var(--color5);
    }

    .singleMenu {
        color: var(--bs-gray-800);
        padding: 8px;
        font-size: 14px;
        border-bottom: 1px dotted var(--bs-gray-300);
    }

    .singleMenu:hover {
        color: var(--bs-info);
    }

    .accordion-body {
        padding: 0 0 0 0;

        .singleMenu,
        .accordion-button {
            padding: 6px 0 6px 30px;
            background: var(--color5);
            border: none
        }
    }

    .activeMenu {
        color: var(--bs-info);
        font-weight: 500;
    }

    .logoutBtn {
        color: var(--primary) !important;
        font-weight: 500;
        transition: all .5s;
        border-top: 1px solid var(--bs-gray-300);
        border-bottom: none;
    }

    .logoutBtn:hover {
        color: var(--color4) !important;
    }
}


.paginationDiv {
    display: flex;
    padding: 0 20px;
    height: 35px;
    margin: 20px 0;
}

.pageHeader {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: start;
    justify-content: space-between;

}

.actionBtn {

    >div {
        position: relative;
        padding-right: 15px;
    }

    >div::after {
        content: '|';
        position: absolute;
        top: 4px;
        right: 0;
        color: #000;
    }

    >div:last-child::after {
        content: none;
        padding-right: 0;
    }
}

.customTabel {

    .listRow {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        border-bottom: 1px solid var(--bs-gray-400);
        line-height: 16px;
    }

    .listRow.head {
        background: var(--bs-secondary);
        color: #fff;
        font-weight: 500;
        padding: 3px 0;
        font-size: 13px;
        text-transform: capitalize;
        border-bottom: none;
    }

    .hasChild {
        border-bottom: none !important;
        background: var(--color5);
    }

    .listRow>div {
        padding: 4px;
        flex-grow: 1;
        width: 16%;
        position: relative;

        .labelText {
            color: var(--bs-secondary);
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
            margin-bottom: 5px;
            position: relative;
            display: none;
        }

        p {
            margin: 0;

        }

        p.mobile {
            line-height: 15px;
        }


    }

    .withlabel>div {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;

        >div:nth-child(2) {
            width: calc(100% - 120px);
            position: relative;
        }

        .labelText {
            font-weight: 500;
            margin: 0;
            text-transform: capitalize;
            color: var(--bs-gray-800);
            width: 120px;
            position: relative;
            display: inline;
        }

        .labelText::after {
            content: ':';
            position: absolute;
            right: 10px;
            top: 0;
        }

    }

    .listRow.cellBorder>div::after {
        content: "";
        position: absolute;
        top: 10%;
        right: 0;
        height: 80%;
        width: 1px;
        background: var(--bs-gray-300);
    }

    .listRow.cellBorder>div:last-child::after {
        content: none;
    }

    .childList {

        border-bottom: 2px solid var(--bs-primary);

        >div:first-child {
            background: var(--bs-gray-600);
            color: #fff;
            font-weight: 500;
        }

        >div {
            border-bottom: 1px solid var(--bs-black) !important;
        }

        >div:last-child {
            border-bottom: none !important;
        }

    }




    .childBooking {
        position: relative;
        .headTitle {
            margin-top: -4px;
            padding: 0 0;
            background: var(--color5);
        }

        .headTitle>div {
            color: var(--bs-link-hover-color);
            padding: 1px 5px;
            font-weight: 600;
           
            position: relative;
        
        }
        .headTitle>div::after {
            content: "";
            position: absolute;
            width: calc(100% - 100px);
            right: 0;
            height: 1px;
            bottom: 8px;
            background: var(--bs-link-hover-color);
        
        }

    }

}

.bookingTabel {
    .listRow:not(.hasChild) {
        border-width: 2px;
        border-color: var(--bs-gray-500)
    }

    ;

    .childBooking .listRow {
        border-width: 1px;
        border-color: var(--bs-gray-300);
        background: var(--color5);
    }

    .childBooking .listRow:last-child {
        border-width: 2px;
        border-color: var(--bs-gray-500);
        position: relative;
    }
    

    .childBooking > .rowBlock:last-child{

        width: 100%;
        position: relative;
        display: block;
        margin: 0 0 0 0;
        background: var(--color2);
        border-bottom: 2px solid #adb5bd;
    }
    
}

.addNewBt {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--secondary);
    cursor: pointer;
    transition: all .3s;

    i {
        font-size: 24px;
        color: var(--primary);
    }
}

.addNewBt:hover {

    color: var(--primary);

}

.holdBtn {
    // margin-left: auto;
    // padding: 10px;

    & #swich1 {
        // margin: 3px;
        box-shadow: none;
    }
}

.moreBtn {
    width: 32px;
    height: 32px;
    background: var(--color5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .5s;
}

.moreBtn:hover {
    background: var(--bs-highlight-bg);
}

.iconBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    >div:last-child {
        font-size: 11px;
        margin: 0;
        line-height: 12px;
    }
}


.searchDiv {

    width: 100%;
    padding: 15px;
    height: auto;
    border: 1px solid var(--bs-border-color);
    margin: 20px 0;
    border-radius: 0;
    position: relative;


    >div:first-child {
        position: absolute;
        background: var(--white);
        padding: 7px;
        top: -20px;
        left: 8px;
        font-size: 15px;
        color: var(--black);

    }

    .formDiv {
        display: flex;
        justify-items: center;
        align-items: end;
        gap: 15px;
        justify-content: flex-start;

        >div {
            width: 200px;
        }
    }

}




.customCheckBox {

    display: block !important;
    position: relative;
    padding-left: 22px;
    margin: 0;
    height: 18px;
    line-height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;

    label {
        width: 100%;
        overflow: hidden;
        font-weight: normal;
        color: #000;
        text-align: left;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #f8f9fa !important;
        border-radius: 0;
        border: 2px solid #adb5bd;
    }


    .checkmark:hover {
        background-color: var(--primary);
    }

    input:checked~label .checkmark {
        border: 3px solid var(--primary);
        background-color: #fff !important;
    }

    input:checked~label .checkmark::after {
        content: '\2713';
        display: inline-block;
        color: var(--primary);
        position: absolute;
        top: -3px;
        left: 2px;
        font-weight: 800;
        font-size: 11px;
    }


}

.checkBoxList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
        width: calc(33.33% - 10px);
        margin: 5px;

        .customCheckBox label {
            font-weight: normal !important;
        }
    }
}

.tebHead {
    position: relative;

    >span {
        font-size: 11px !important;
    }

    .alertIcon {
        position: absolute;
        top: -8px;
        right: -8px;

        >div {
            width: 20px;
            height: 20px;
            color: var(--color4) !important;
        }

        i {
            font-size: 20px;
            line-height: 20px;
        }
    }
}




.fileUploader {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    background: var(--color5);
    padding: 10px;
    gap: 20px;

    .docList {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        gap: 10px;
        flex-wrap: wrap;

        >div {
            width: 70px;
            text-align: center;
            line-height: 14px;
            font-size: 10px;
            position: relative;

            img {
                width: 50px;
                height: auto;
            }

        }

        .delIcon {
            position: absolute;
            top: 28px;
            right: 6px;
            width: 26px;
            height: 26px;
            background: var(--color5);
            color: #f00;
            border-radius: 50%;
            cursor: pointer;
            padding: 5px;
        }

    }

}


.timeSlot {

    tr {
        line-height: 18px;
    }

    .bs-timepicker-field {
        padding: 0;
        width: 60px;
    }

    .btn {
        border-width: 0px !important;
        color: var(--secondary);
    }
}



.inDetail {
    display: flex;
    align-items: start;
    justify-items: stretch;
    justify-content: space-between;
    gap: 10px;


    >div:first-child {
        width: calc(100% - 300px);


        >div {
            margin-bottom: 10px;
        }

    }

    .activityDiv {
        width: 260px;
        position: relative;

    }

    .listRow>div {

        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;

        >div:nth-child(2) {
            width: calc(100% - 120px);
            position: relative;
        }

        .labelText {
            font-weight: 500;
            margin: 0;
            text-transform: capitalize;
            color: var(--bs-gray-800);
            width: 120px;
            position: relative;
            display: inline;
        }

        .labelText::after {
            content: ':';
            position: absolute;
            right: 10px;
            top: 0;
        }

    }

    .detailHead {
        background: var(--secondary);
        color: var(--bs-gray-200);
        width: 100%;

        >div:nth-child(2) {
            width: 75px;
        }

        >div:first-child {
            width: calc(100% - 100px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;
            width: 100%;
        }
    }

    .fileUploader {
        background: none;
        padding: 0;

        .docList>div img {
            width: 30px;
        }
    }

    .fullWidth {
        width: 100%;
    }


}


.activityDiv {


    >div {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 5px;

        .calling {
            background: var(--color5);
            margin-bottom: 25px;
            padding: 10px;

            .mobile {
                font-size: 20px;
                text-align: center;

                i {
                    font-size: 20px;
                }
            }

            .callBtn {
                width: auto;
                padding: 5px;
                background: #f00;
            }

        }
    }

}


.mapDiv {
    width: 100%;
    height: calc(100vh - 360px);
    background: url('./assets/images/map.PNG') no-repeat;
    background-size: cover;
    border: 1px solid var(--bs-gray-400);
    display: flex;
    justify-items: stretch;
    justify-content: stretch;
    flex: 1;

    app-map-view {
        flex: 1;
        display: flex;

        .mapView {
            flex: 1;
        }

    }
}

.engagedLeads {
    display: flex;
    width: 100%;
    height: auto;

    justify-content: center;
    align-items: center;

    >div {}
}

.addressHistory {
    display: flex;
    gap: 10px;
    justify-content: stretch;
    justify-items: stretch;
    background: var(--color5);
    padding: 8px;

    >div {
        background: var(--bs-gray-100);
        border: 1px solid var(--bs-gray-300);
        padding: 6px;
        width: 25%;

        p {
            margin: 0;
        }

        .smallTxt {
            font-size: 90%;
            line-height: 12px;
        }
    }

    >div:last-child {
        display: flex;
        align-items: end;
        margin-left: auto;
        background: none;
        border: none;
    }

    .selected {
        background: var(--bs-highlight-bg);
        border: 1px solid var(--bs-primary);
    }
}

.notification .customBadge {
    position: absolute;
    top: 8px;
    left: -5px;
    font-weight: normal;
}

.timepicker {
    input {
        border: 1px solid var(--bs-gray-500) !important;
        border-bottom: 2px solid var(--bs-danger) !important;
    }
}


.status_0,
.status_200 {
    border-color: var(--bs-primary);
    border-width: 2px;
}

.status_0:checked,
.status_200:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.status_10,
.status_201 {
    border-color: var(--bs-success);
    border-width: 2px;
}

.status_10:checked,
.status_201:checked {
    background-color: var(--bs-success);
    border-color: var(--bs-success);
}

.status_70,
.status_300 {
    border-color: var(--bs-cyan);
    border-width: 2px;
}

.status_70:checked,
.status_300:checked {
    background-color: var(--bs-cyan);
    border-color: var(--bs-cyan);

}

.status_50,
.status_500 {
    border-color: var(--bs-info);
    border-width: 2px;
}

.status_50:checked,
.status_500:checked {
    background-color: var(--bs-info);
    border-color: var(--bs-info);

}

.status_40,
.status_400 {
    border-color: var(--bs-red);
    border-width: 2px;
}

.status_40:checked,
.status_400:checked {
    background-color: var(--bs-red);
    border-color: var(--bs-red);

}

.status_40 {
    border-color: var(--bs-red);
    border-width: 2px;
}

.status_40:checked {
    background-color: var(--bs-red);
    border-color: var(--bs-red);

}

.status_600 {
    border-color: var(--bs-black);
    border-width: 2px;
}

.status_600:checked {
    background-color: var(--bs-black);
    border-color: var(--bs-black);
}

.status_700 {
    border-color: var(--bs-pink);
    border-width: 2px;
}

.status_700:checked {
    background-color: var(--bs-pink);
    border-color: var(--bs-pink);

}



.noticeDiv {
    position: absolute;

    top: 5px;
    left: 225px;

    margin: auto;
    height: 42px;
    width: 280px;
    font-size: 12px;
    padding: 6px;
    line-height: 16px;
    background: #dee3e8;

    p {
        padding: 0;
        margin: 0;
    }
}


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    background: var(--color2);
    font-size: 11px;
    z-index: 10;
}

.custum-overflow {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}


.w-12 {
    width: 12% !important;
}

.w-20 {
    width: 20% !important;
}

.theme-dark-blue .bs-datepicker-head {
    background-color: var(--bs-secondary) !important;
}


.wrapper {
    overflow: auto !important;
}

.ameyoZindex{z-index: 10000;}





/*#####################################################################*/
/*#####################################################################*/


.inOverview {
    display: flex;
    align-items: start;
    justify-items: stretch;
    justify-content: space-between;
    gap: 40px;


    >div:first-child {
        width: 85%;
        line-height: 15px;

        >div {
            margin-bottom: 40px;
        }

    }

    .priorityDiv {
        width: 25%;
        position: relative;

    }

    .listRow>div {

        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;

        >div:nth-child(2) {
            width: calc(100% - 120px);
            position: relative;
        }

        .labelText {
            font-weight: 500;
            margin: 0;
            text-transform: capitalize;
            color: var(--bs-gray-800);
            width: 120px;
            position: relative;
            display: inline;
        }

        .labelText::after {
            content: ':';
            position: absolute;
            right: 10px;
            top: 0;
        }

    }

    .detailHead {
        background: var(--secondary);
        color: var(--bs-gray-200);
        width: 100%;

        >div:nth-child(2) {
            width: 75px;
        }

        >div:first-child {
            width: calc(100% - 100px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;
            width: 100%;
        }
    }

    .fileUploader {
        background: none;
        padding: 0;

        .docList>div img {
            width: 30px;
        }
    }

    .fullWidth {
        width: 100%;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .row>div[class*='col-'] {
        display: flex;
    }
}



table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

}

th.mat-header-cell {
    text-align: left;
    max-width: 300px;
}

.table-row {
    background-color: #FFFFFF;
}

.table-row__alternate {
    background-color: #F1F5F9;
}

.table-header {
    background-color: #CBD8E2;
    height: 45px !important;
}

.table-Smalltext {
    font-size: 12px;
}

.table-FilterSearch {
    background-color: #FFFFFF;
    color: #000;
}


//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';





.search_box {
    width: 300px;
    background: #fff;
    border-radius: 5px;
    height: 35px;
    display: flex;
    padding: 5px 0px;
    box-shadow: 0 8px 6px -10px #b3c6ff;
}


.search_box .search_field {
    width: 250px;
    height: 100%;
    position: relative;
}

.search_box .search_field .input {
    width: 85%;
    height: 100%;
    border: 0px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 38px;
    color: #000;
    border: none;
    -webkit-appearance: none !important;
}

.search_box .search_field input:focus {
    outline: none !important;
}


.searchButton {
    border: none !important;
    background: none !important;
}

.search_box select {
    width: 30%;
    -webkit-appearance: listbox !important;
    border: none;
    //font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.search_dropdown {
    width: 240px;
    background: #fff;
    border-radius: 5px;
    height: 35px;
    display: flex;
    padding: 5px 0px;
    box-shadow: 0 8px 6px -10px #b3c6ff;
}

.search_dropdown select {
    width: 90%;
    -webkit-appearance: listbox !important;
    border: none;
    //font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
}


.fragment {
    font-size: 12px;
    font-family: tahoma;
    // height: 140px;
    border: 1px solid #ccc;
    color: #555;
    display: block;
    padding: 10px;
    box-sizing: border-box;
    text-decoration: none;
}

.fragment:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);

}

#close {
    float: right;
    display: inline-block;
    padding: 2px 5px;
    background: #ccc;
}

#close:hover {
    float: right;
    display: inline-block;
    padding: 2px 5px;
    background: #ccc;
    color: #fff;
}

.advanceSearchFilter {
    width: 600px;
    position: relative;
    margin-left: auto;
    right: 0%;

    background: #CBD7E1;
    padding: 10px;
    margin-bottom: 10px;
}

.btnadvanceSearch {
    background-color: white;
    color: black;
    width: 10px
}

.btnInbound {
    background-color: white;
    color: black;
    width: 120px
}

/*Amitcss*/
.btn-white {
    background-color: #fff;
    border-radius: 5px !important;
    color: #000;
}

.cdk-overlay-connected-position-bounding-box {
    top: 53.5px !important;
}

.cs-dropdown {
    min-width: 165px !important;
    padding: 0px 10px;

}

.toggle-swich {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 14px 0px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.gt-gap {
    gap: 11px !important;
}

.filter-btn {
    min-width: 44px !important;
    height: 35px !important;
    padding: 0px 0px !important;
}

.bg-iight {
    border-radius: 4px;
    background-color: #FFF;
}

.cs-form .form-label {
    font-size: 12px;
    color: #000;
}

.cs-form .form-control,
.cs-form .form-select {
    // border-radius: 4px;
    // font-size: 13px;
    // border: unset;
    // color: #000;
    // background-color: #EFF0F2;
}

.table-header th {
    color: #000;
    font-size: 12px !important;
}

tr.mat-row,
tr.mat-footer-row {
    height: 43px !important;
}

.list-drop .dropdown-menu {
    padding: 0;
    transform: translate(-12px, 30px) !important;
}

.list-drop .dropdown-menu .list-group-item {
    border-bottom: none;
    font-size: 12px;
    color: #000;
    display: flex;
    justify-content: space-between;
}

.list-drop .dropdown-menu .list-group-item i {
    font-size: 14px;
}

.mat-cell,
.mat-footer-cell {
    font-size: 12px;
}

.mat-sort-header-content {
    font-size: 12px;
}

.white-card {
    background-color: #fff;
    padding: 12px 12px;
    border-radius: 5px;
}

.text-light2 {
    font-weight: lighter;
}

.f-12 {
    font-size: 12px !important;
}

.cs_btn {
    height: 33px;
    line-height: 0px !important;
    font-size: 12px !important;
    min-width: auto;
}

.btn-secondary1 {
    background-color: #EEEFF0;
    border: 1px solid #194569 !important;
    color: #194569;
}

.btn-secondary1:hover {
    color: #194569;
}

.btn-primary1 {
    background-color: #194569;
    border: 1px solid #194569;
}

.btn-primary1:hover {
    color: #194569;
}

.btn-outline-primary1 {
    background-color: transparent;
    border: 1px solid #194569 !important;
    color: #194569;
}

.color-white {
    color: #fff;
}

.btn-outline-primary1:hover {
    background-color: #194569;
    color: #fff;
}

.cs_tabs .nav-link {
    // background-color: #D9D9D9 !important;
    // padding: 8px 33px !important;
}

.acti-box {
    padding: 0px 12px;
}

.acti-row {
    background-color: #EFF0F2;
    border-radius: 4px;
    padding: 10px 10px;
}

.user-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d4d4d4;
    text-align: center;
    margin: 0px 18px 0px 10px;
}

.user-img i {
    font-size: 31px;
}

.meta_text {
    font-size: 12px;
    color: #000;
    opacity: 0.8;
    padding: 10px 0px 0px 0px;
}

.mat-table {
    font-family: 'Open Sans', sans-serif !important;
}

.tatCrossed1 {
    background-color: #87CEEB;
}

.tatCrossed2 {
    background-color: #FFCCCB
}

.tatCrossed3 {
    background-color: #FF8282;
}

.worktypeMenu .form-check-input {
    margin-left: -1em !important;
    margin-right: 10px !important;
}

.btnworktype {
    height: 42px;
    width: 150px;
    position: relative;
    bottom: 4px;
    border: none;
}

.tableHead {
    background-color: var(--secondary) !important;
    color: #FFF;
    font-weight: 500;
    padding: 3px 2px;
    font-size: 13px;
    text-transform: capitalize;
    line-height: 16px;
    height: 38px !important;
    padding: 8px;
    ;
}

.tablegrid a {
    color: #0631cd;
    font-weight: bold;
}

//  .ng-star-inserted{
//     display: none;
//  }

.assignAllBt {
    display: flex;
    align-items: center;
    gap: 2px;
    color: #fff;
    cursor: pointer;
    transition: all .3s;

    i {
        font-size: 24px;
        color: #fff;
    }
}

.detailLeftDiv {
    // width: 220px !important;
    // position: relative;

}

.NgxEditor {
    height: 150px;
    overflow-y: scroll;
}

.fileAttachment {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    padding: 10px;
    padding-left: 0px !important;
    gap: 20px;

    .docList {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        gap: 10px;
        flex-wrap: wrap;

        >div {
            width: 70px;
            text-align: center;
            line-height: 14px;
            font-size: 10px;
            position: relative;

            img {
                width: 50px;
                height: auto;
            }

        }

        .delIcon {
            position: absolute;
            top: 28px;
            right: 6px;
            width: 26px;
            height: 26px;

            color: #f00;
            border-radius: 50%;
            cursor: pointer;
            padding: 5px;
        }

    }

}

.submitType label:hover {
    text-decoration: underline;
    color: #072281;
    cursor: pointer;
}

//   .leftUpdateDiv{
//     margin-left: 20px; padding-bottom: 20px;padding-left: 20px; background-color: #fff;
//   }
.leftUpdateDiv .lbOutoComplete .form-select,
.lbDropDown {

    width: 106%;
    
}

.formLable {
    font-size: 12px;
    color: #000;
}

.formtextLeftDiv {

    width: 106% !important;
    margin-left: -10px !important;
}

.inBoundLinkButton {
    color: var(--secondary);
    cursor: pointer;
    margin-left: 10px;
}


.NgxEditor {
    min-height: 150px;
    height: auto;
}

.internalNotes .NgxEditor {
    background-color: #FFF7D6 !important;
}

.internalNotesCCG .NgxEditor {
    background-color: #EFF0F2 !important;
}

.filterDivBtn {
    position: relative;
    margin-left: auto;
    right: -15px
}

.tktDetailLeft {
    margin-left: 20px;
    padding-bottom: 20px
}

.tktDetailLeft-inner {
    padding-left: 10px;
    background-color: #F1F5F9
}

.tktUpdateBtn {
    text-align: right;
    padding-right: 16px
}

.NgxEditor p {
    margin: 0 0 0px !important;
}


.toogle-content {
    max-width: 175px !important;
}    
.ng2-tag-input__text-input{
    height: 20px !important;
    width: 250px !important;
}

.activeDND{
    pointer-events: none;
    opacity: .6;
  }

.icon-50 { 
    font-size: 40px; 
 }

 .delAttachmentIcon {
    position: absolute;
    top: 18px;
    right: 2px;
    width: 26px;
    height: 26px;
    background: var(--color5);
    color: #f00 !important;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
}

.delAttachmentIcon :hover {
    color: #f00 !important;
  }

  .blink {
    animation: blinker 1s step-start infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .boxDiv {

    width: 100%;
    padding: 15px;
    height: auto;
    border: 1px solid var(--bs-border-color);
    margin-top: 20px;
    border-radius: 0;
    position: relative;


    >div:first-child {
        position: absolute;
        background: var(--white);
        padding: 7px;
        top: -20px;
        left: 8px;
        font-size: 15px;
        color: var(--black);

    }

    .formDiv {
        display: flex;
        justify-items: center;
        align-items: end;
        gap: 15px;
        justify-content: flex-start;

        >div {
            width: 200px;
        }
    }

}
.reportFilterdiv{
    width: 200px; 
}

.mat-mdc-tab-group {
    max-width: 99.99%;
 }

 .cdk-overlay-container {
   display:none !important;
}


td.mat-cell {
    /* row padding */
    padding: 5px;
    /* row border */
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding-right: 5px !important;
    padding-left: 5px !important;
   }
   
   td.mat-cell:first-child {
     /* row border */
     border-left: 1px solid rgba(0, 0, 0, 0.25);
   }
   
   td.mat-cell:last-child {
     /* row border */
     border-right:1px solid rgba(0, 0, 0, 0.25);
   }
    
 .ccg_escalation_level-2{
    background-color:#ff00004d;
 }

 .ccg_escalation_level-1{
    background-color: #ffa5006e;
 }