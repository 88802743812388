//$font-family-base:"'Roboto', sans-serif";
$font-family-base:"'Open Sans', sans-serif";

$primary: #ffcc00;
$secondary:#204570;
$danger: #ff8282;
$success:#70ff70;
$light:#f0f0f0;
$info:#6f42c1;
$pink:#ff42c1;
$modal-backdrop-bg:#b0d0ff;

$color1: #f0f0f0;
$color2: #e9e9e9;
$color3: #fbfbfb;
$color4: #ff0000;
$color5: #f3f6f9;
$color6: #22cc00;




$border-radius:3px;
$border-radius-sm:4px;
$border-radius-lg:4px;
$border-radius-pill:20px;

$form-label-margin-bottom:1px;
$form-label-font-weight:500;
$form-label-font-size:1rem;

$offcanvas-horizontal-width:275px;
$offcanvas-padding-y:12px;
$offcanvas-padding-x:12px;

$accordion-button-focus-border-color:'none';
$accordion-button-focus-box-shadow:'none';  

$input-border-width: 1px;
$input-border-color: var(--bs-gray-500);
$input-border-radius:0px;
$input-bg:$color3;
$input-padding-x:5px;
$input-padding-y:1px;
$input-line-height:2.0;
//$input-focus-border-color:none;
$input-focus-box-shadow:0 2px 5px rgba(255, 174, 0, 0.2)!important;
$input-placeholder-color:var(--bs-gray-500);



::-webkit-input-placeholder {
  font-size: 13px;
}

$btn-font-size:14px;
$btn-font-weight:500;
$btn-padding-y:2px;
$btn-padding-x:6px;
$btn-border-radius:0;


$dropdown-padding-y:0;
$dropdown-item-padding-x:4px;


$modal-content-border-width: 1;
$modal-content-border-radius:0;
$modal-header-padding-y:5px;
$modal-header-padding-x:8px;


$alert-padding-y:12px;
$alert-padding-x:10px;
$alert-margin-bottom:1rem;
$alert-border-radius:0px;
$alert-link-font-weight:500px;
$alert-border-width:1px;
$alert-dismissible-padding-r: 3px;

$accordion-button-active-bg:none;
/*$accordion-button-active-color:none;
$accordion-padding-y:0;
$accordion-padding-x:0;
$accordion-border-width:0;
$accordion-border-color:none;
$accordion-border-radius:0;
$accordion-inner-border-radius:0; 
*/ 

$nav-tabs-border-color:$secondary;
$nav-tabs-link-active-border-color:$secondary $secondary  var(--white);
$nav-link-color:var(--bs-gray-600);
$nav-link-font-weight:500;




:root {
  --white: #fff;
  --black: #000;
  --primary:#{$primary};
  --secondary:#{$secondary};
  --color2:#{$color2};
  --color4:#{$color4};
  --color5:#{$color5};
  --color6:#{$color6};
}

body, html{
 font-size: 12px !important;
 color: $secondary !important;
 font-weight: 400 !important;
 
 i{
  font-size: 15px;
 }
}


.w-30{width: 30% !important;}
.w-70{width: 70% !important;}
.w-40{width: 40% !important;}
.w-60{width: 60% !important;}
.w-1by3{width: 66.66% !important;}
.text-10p{font-size: 10px;}

.ml-auto{margin-left: auto !important;}
.text-light2{color:var(--bs-gray-400) !important;}
.text-green{color:var(--color6) !important;}
.text-red{color:var(--color4) !important;}

a{font-size: 1.1rem; font-weight: 500;}

.nav-link.active{font-weight: 600 !important; color: $secondary !important;}

.btn{
  font-size: 14px !important;
  border-width: 1px!important;
  border-color: rgba(0,0,0,.2) !important;
}
.alert-dismissible {
  padding-right: 32px !important;
}


.dropdown-menu{
        border: 1px solid #e9e9e9 !important;
        background: var(--white);
        border-radius: 0 !important;
        margin-top: -2px !important;

        li{transition: all .5s; border-bottom: 1px solid var(--bs-gray-400);}
        li:hover{
            background: var(--bs-highlight-bg);
        }

        .list-group-item{
          padding: 6px 8px !important;
          font-weight: inherit !important;
          
        }
}

.form-check-inline {
  display: inline-block;
  margin-right: 20px !important;
}
.form-switch {
  padding-left: 2.5em;
  label{display: inline; margin-left: 2px;}
  position: relative;
}

.form-check
{
  padding-left: 16px !important;
  line-height: 18px;
  .form-check-input{
  margin-left: -16px !important;
}



} 
.notValid{

  border-bottom: 2px solid var(--bs-danger) !important;
}


.inActive{
  pointer-events: none;
  opacity: .5;
}


.borderLoader::after{
  content: ""; width: 10%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left:0;
  background:$color4;
  animation: fill 4s linear infinite;
  
}

.shadowSm1{box-shadow: 0 2px 4px rgba(0,0,0,0.1)!important;}
.bottomShadow{box-shadow: 0 3px 10px rgba(0,0,0,0.1)!important;}
.smallTxt {
  font-size: smaller;
  line-height: 12px;
}

.baseLoader {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  align-items: center;
  width: 100%;
  height: 100vh;

  justify-content: center;
  z-index: 10000;

  img {
      display: block;
  }

}

.toastContainer {
  position: fixed;
  width: auto;
  display: flex;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  overflow: auto;

  >div {
      width: 300px;
      pointer-events: all;
  }
}


.heading1 {
  font-size: 24px;
  width: auto;
  height: auto;
  min-height: 25px;
  display: inline-block;
  font-weight: 500;
  color: #000;
  padding: 0 0 0 10px;
  position: relative;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.heading2{
  font-size: 18px;
  width: auto;
  height: auto;
  min-height: 25px;
  display: inline-block;
  font-weight: 500;
  color: #000;
  padding: 0 0 0 0;
  position: relative;
  margin-bottom: 25px;
  text-transform: capitalize;

  span{font-size: 13px; color: var(--bs-gray-500);}
}

.heading3{
  font-size: 16px;
  width: auto;
  height: auto;
  min-height: 18px;
  display: inline-block;
  font-weight: 500;
  color: var(--white);
  padding: 0 0 0 10px;
  position: relative;
  text-transform: capitalize;

}

.before1:before {
  content: "";
  width: 4px;
  height: 80%;
  border-radius: 0;
  background: $primary;
  position: absolute;
  bottom: 10%;
  left: 0;
}

.after1:before {
  content: "";
  width: 8px;
  height: 3px;
  border-radius: 10px;
  background: $primary;
  position: absolute;
  bottom: -2px;
  left: calc(50% + 6px);
}

.after1:after {
  content: "";
  width: 50%;
  height: 3px;
  border-radius: 10px;
  background: $primary;
  position: absolute;
  bottom: -2px;
  left: 0;
}


.closeBtn{
  color: rgba(255,255,255,.5);
  cursor: pointer;

  i{
    font-size: 23px !important;
   }
 
}
.closeBtn:hover{
  color: $primary;
}

.cursorPointer{
  cursor: pointer;
  >*{cursor: pointer;}
}

.bigIcon{
  i{
   font-size: 32px !important;
  }
  }


  .customBadge{
    display: inline-block;
    padding: 1px 5px;
    border-radius: 25px;
    background:$color1;
    font-size: 11px;
    font-weight: 600;
    
  
  } 
  

  .bs-datepicker-container {
    padding: 0 !important;
  }
  .theme-default .bs-datepicker-head {
    background-color: var(--bs-gray-800) !important
  }
  
  .bs-datepicker-body table td {
    color: $secondary !important;
  }
  
  .theme-default .bs-datepicker-body table td span.selected,
  .theme-default .bs-datepicker-body table td.selected span,
  .theme-default .bs-datepicker-body table td span[class*=select-]:after,
  .theme-default .bs-datepicker-body table td[class*=select-] span:after {
    background-color: $primary !important;
  }
  
  .bs-datepicker-body table td span {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .rangeInput{

    .minMaxVal{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -5px;
    }
  }

  .modal-body-scroll{
    max-height: calc(100vh - 100px);
    overflow: auto;
    position: relative;
  }


  .full-screen-modal{
    height: 100vh !important;
    width: 100vw !important;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }  

  .rotate {
    animation: rotation 2s infinite linear;
  }

  .zoomScale {
    animation: zoomScale 1s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
     
    }
    to {
      transform: rotate(359deg);
     
    }
  }

  @keyframes zoomScale {
    0% {
      
      transform: scale(.75, .75);
    }
    50% {
      
      transform: scale(1.2, 1.2);
    }
    100% {
      
      transform: scale(.7, .7);
    }
  }



@keyframes fill{
  0%{
    left:0;
    right: 100%;
    width: 2%;
  }

  50%{
    left:30%;
    right: 0;
    width: 30%;
  }

  100%{
    right:0;
    left: 100%;
    width: 2%;
  }
}




@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "./map.scss";


